/* You can add global styles to this file, and also import other style files */
/* .modal-xl .modal-dialog .modal-content {
  max-width: 50% !important;
  margin-left: 350px !important;
}

.modal-lg .modal-dialog .modal-content {
  max-width: 50% !important;
  margin-left: 350px !important;
}

.weight-500 {
  font-weight: 500;
}

.text-gray {
  color: #777;
}

.text-md {
  font-size: 0.9em;
}

.text-sm {
  font-size: 0.75em;
}

.nowrap {
  white-space: nowrap;
}

.td-link {
  color: #05e !important;
  cursor: pointer;
}

.td-link:hover {
  background: rgb(203, 228, 255);
}



.modal .modal-dialog .modal-content .modal-header {
  padding: 0.7em 1.5em !important;
  position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    border-bottom: 1px solid #ccc;
}

.modal-title {
  font-size: 1.1em !important;
  font-weight: bold !important;
  align-items: center !important;
}

.no-records-found {
  margin: auto;
  color: #ddd;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.no-records-found i {
  font-size: 48px;
}

.no-records-found p {
  font-size: 13px;
  color: #bbb;
}

.sorticon:not(.col-sorted-asc):not(.col-sorted-desc)::after {
  font-family: 'FontAwesome';
  content: '\f0dc';
  margin-left: 2px;
  color: #777;
}

.col-sorted-asc::after {
  font-family: 'FontAwesome';
  content: '\f160';
  margin-left: 2px;
}

.col-sorted-desc::after {
  font-family: 'FontAwesome';
  content: '\f161';
  margin-left: 2px;
}

.blurzero {
  color: #ccc;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.fullscreen-modal {
  margin: 0 !important;
  max-width: unset !important;
  max-height: unset !important;
}

.fullscreen-modal .modal-content {
  position: relative;
  border-radius: 0 !important;
  min-height: 100vh;
  width: 100% !important;
  left: 0% !important;
  border-radius: unset !important;
}
